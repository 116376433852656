<template>
<!--     检查报告发单详情页 -->
    <div class="cha">
    <h3 class="cha1">基本信息</h3>
    <div class="cha2">
        <div class="cha21">
            <div style="padding-left:8px;color:#A4A5AB">就诊人姓名：</div>
            <div style="padding-right:8px">{{info1.name}}</div>
        </div>
         <div class="cha21">
            <div style="padding-left:8px;color:#A4A5AB">年龄：</div>
            <div style="padding-right:8px">{{info1.age}}{{info1.agetype}}</div>
        </div>
         
         <div class="cha21">
            <div style="padding-left:8px;color:#A4A5AB">报告医生：</div>
            <div style="padding-right:8px">{{info1.doctorName}}</div>
        </div>
         <div class="cha21">
            <div style="padding-left:8px;color:#A4A5AB">开单科室：</div>
            <div style="padding-right:8px">{{info1.deptName}}</div>
        </div>
         <div class="cha21">
            <div style="padding-left:8px;color:#A4A5AB">报告时间：</div>
            <div style="padding-right:8px">{{info1.photoDate}}</div>
        </div>
        <div class="cha21">
            <div style="padding-left:8px;color:#A4A5AB">报告单号：</div>
            <div style="padding-right:8px">{{info1.recordId}}</div>
        </div>
    </div>
     <h3 class="cha1">报告结果</h3>
     <div class="cha5">
       {{info1.examdesc}}
     </div>
       <h3 class="cha1">诊断结果</h3>
       <div class="cha5">{{info1.examdiagnosis}}</div>
    </div>
</template>
<script>
export default {
    data(){
        return{
        info1:{}
        }
    },
      mounted(){
      this.info1 = JSON.parse(window.sessionStorage.getItem('visionList'))
 
  }
}
</script>
<style scoped>
.cha{
    width:100%;
    height: 100%;
}
.cha1{
    width:9rem;
    height:40px;
    line-height: 40px;
    margin: auto;
    text-align: left;
  
    font-size: 16px;
}
.cha2{
     width:9rem;
     height:250px;
     margin: auto;
     background-color: #fff;
    border-radius: 10px;
}
.cha21{
    height:40px;
 
    display: flex;
    font-size:16px;
    justify-content: space-between;
    align-items: center;
}
.cha5{
    width:9rem;
    padding: 5px;
    box-sizing: border-box;
     margin: auto;
     background-color: #fff;
    border-radius: 10px;
    font-size: 16px;
    text-align: left;
    line-height: 30px;
}
</style>